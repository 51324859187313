import { createContext, ReactNode, useContext } from 'react';
import { TenantConfig, TenantLocationConfig } from '~/tenants/common/registry';
import { TimezoneContextProvider } from '~/components/hooks/useTz';
import { useParams } from 'react-router';
import TenantWrapper from '~/tenants/common/TenantWrapper';

export type TenantClientConfig = { meili_url: string; meili_key: string; meili_prefix: string };
type TenantClientContext = TenantConfig & TenantClientConfig;

const context = createContext<TenantClientContext | null>(null);
const locationContext = createContext<TenantLocationConfig | null>(null);

export function TenantContextProvider(props: { tenant: TenantClientContext; children: ReactNode }) {
  return (
    <context.Provider value={props.tenant}>
      <TimezoneContextProvider timezone={props.tenant.locations[0].timezone}>{props.children}</TimezoneContextProvider>
    </context.Provider>
  );
}

export function TenantLocationProvider(props: { location: TenantLocationConfig | string; children: ReactNode }) {
  const tenant = useTenant();

  const location =
    typeof props.location === 'string' ? tenant.locations.find((l) => l.slug === props.location) : props.location;

  if (!location) {
    throw new Error(`Location ${props.location} could not be found for <TenantLocationProvider />.`);
  }

  return (
    <locationContext.Provider value={location}>
      <TimezoneContextProvider timezone={location.timezone}>{props.children}</TimezoneContextProvider>
    </locationContext.Provider>
  );
}

export function useTenant() {
  const tenant = useContext(context);

  if (tenant === null) {
    throw new Error('TenantContextProvider not found');
  }

  return tenant;
}

export function useTenantWrapper() {
  const tenant = useTenant();

  return new TenantWrapper(tenant);
}

export function usePerformable(id: string) {
  const tenant = useTenant();

  const performable = tenant.performables[id];

  if (!performable) {
    throw new Error(`Performable with id ${id} not found`);
  }

  return performable;
}

export function useTenantLocation(safe = false) {
  const context = useContext(locationContext);

  if (context) {
    return context;
  }

  const tenant = useTenant();
  const params = useParams();

  if (!params.location) {
    if (safe) {
      return tenant.locations[0];
    }

    throw new Error(`Location not found in URL and missing <TenantLocationProvider />.`);
  }

  const location = tenant.locations.find((l) => l.slug === params.location);

  if (!location) {
    throw new Error(`Location ${params.location} not found.`);
  }

  return location;
}
