import { TenantConfig } from '~/tenants/common/registry';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { DateTime } from 'luxon';
import { holiday_lookup } from '~common/holidays/holidays';

export default class TenantWrapper {
  constructor(private config: TenantConfig) {}

  get logo() {
    return this.config.logoVector ?? this.config.logoRaster;
  }

  provider(id: string) {
    const data = this.config.providers[id];

    if (!data) {
      throw new Error(`Provider with id ${id} not found`);
    }

    return new this.config.providerClass(id, data);
  }

  order(context: TenantOrderContext) {
    return new this.config.orderClass(this.config, context);
  }

  isHoliday(date: DateTime) {
    if (!this.config.holidays) {
      return false;
    }

    const holidays = holiday_lookup(date);

    return holidays.some((h) => this.config.holidays?.includes(h));
  }

  form<T extends keyof TenantConfig['forms']>(type: T): TenantConfig['forms'][T] {
    return this.config.forms[type];
  }
}
